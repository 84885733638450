import React from "react"

import { css } from '@emotion/react'
import { colors } from "../styles/constants"

const EventCard = (props) => {
    const {
        title,
        excerpt,
        date,
        place,
        cta,
        url,
    } = props

    return (
        <div css={main}>
            <h4>événement</h4>
            <h3>{title}</h3>
            <div className="event-card__meta">
                {place}
                {date && <span>{date}</span>}
            </div>
            <p>{excerpt}</p>
            {cta && 
                <div>
                    {url ? 
                        <a href={url} rel="noopener noreferrer nofollow" target="_blank">{cta}</a>
                        :
                        <a href="mailto:hello@lyketil.com">{cta}</a>
                    }
                </div>
            }
        </div>
    )
}

export default EventCard

const main = css`
    padding: 20px;
    border-radius: 10px;
    background-color: white;

    .event-card__meta {
        margin: 10px 0;
        display: inline-block;
        padding: 5px 15px;
        border: 1px solid ${colors.lightgrey};
        color: ${colors.midgrey};

        span {
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid ${colors.lightgrey};
        }
    }

    h4 {
        margin: 0 0 5px;
        text-transform: uppercase;
        font-size: .6em;
        font-weight: 700;
        letter-spacing: 2px;
        color: ${colors.tv3};
    }

    h3 {
        margin: 0;
        font-size: 1.2em;
    }

    p {
        marign: 0;
    }
`