import React, { useState } from "react"

import { css } from '@emotion/react'

const SendInBlueForm = (props) => {

    const [open, setOpen] = useState(false)

    const handleToggleModal = () => {
        setOpen(!open)
    }

    return (
        <div>
            <button 
                onClick={handleToggleModal}  
                className="button--primary pink"
                data-button-goal={props.form_title}
            >{props.title}</button>

            {open && <div css={modal}>
                <div css={modalInner}>
                    <button 
                        onClick={handleToggleModal}
                        className="button--primary"
                        css={closeMe}
                        >
                            <span>X</span> Fermer
                    </button>
                    <iframe 
                        width="100%" 
                        height="100%" 
                        title={props.form_title ? props.form_title : "SendinBlue Form"}
                        src={props.src}
                        frameborder="0" scrolling="auto" allowfullscreen 
                        style={{display: "block",marginLeft: "auto", marginRight: "auto", maxWidth: "100%"}}
                        >
                    </iframe>
                </div>
            </div>}
        </div>
    )
}

export default SendInBlueForm

const modal = css`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 20000;
`

const modalInner = css`
    position:fixed;
    background: white;
    width: 50vw;
    min-width: 400px;
    height: 80vh;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
`

const closeMe = css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    top: 20px;
    right: 20px;
    padding: 20px 20px;
    background-color: black;
    color: white;
    border-radius: 5px;

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        margin-right: 10px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.33);
        font-weight: 800;
    }
`
