import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { css } from '@emotion/react'
import { colors } from '../styles/constants'

const Programs = () => {
    const data = useStaticQuery(graphql`
      query ProgramsQuery {
        allPrismicProgram(filter: {tags: {eq: "Video"}}) {
          edges {
            node {
              id
              data {
                name {
                  text
                }
                desc
                background_color
                white_text
                tracks {
                  track {
                    document {
                      ... on PrismicTrack {
                        id
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                main_tool {
                  document {
                    ... on PrismicTool {
                      id
                      data {
                        logo {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `)

    const program = data.allPrismicProgram.edges[0].node.data
    const logo = program.main_tool.document.data.logo

    // console.log(data)

    return (
        <section css={wrapper}>
            <div className="container">
                <div className="doodle">Votre mission, si vous l'acceptez <span role="img" aria-label="regarder en dessous">👇</span></div>
                <h2 style={{marginTop: "0"}}>Apprenez le logiciel vidéo de référence pour PME</h2>
                <p style={{marginBottom: "20px"}}>VideoLab vous a préparé un programme particuièrement adapté à vos besoins d'apprentissage rapide, concret et simple de la vidéo pour votre entreprise, avec un outil de référence : Adobe Premiere Rush (abonnement non inclus).</p>
                <div css={card} style={program.background_color ? {backgroundColor: `${program.background_color}`} : {backgroundColor: "white"}}>
                    <GatsbyImage image={logo.gatsbyImageData} alt={program.name.text} />
                    <div css={program.white_text && whiteMode}>
                        <h3>{program.name.text}</h3>
                        <p>{program.desc}</p>
                        <div css={meta}><span role="img" aria-label="disponibilité">🔥</span> Disponible sur MacOS, iOS, PC, Android</div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Programs

const wrapper = css`
    padding: 80px 0;
    background-color: ${colors.whitegrey};
`

const card = css`
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .11);
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    align-items: center;
    
    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 4fr;
    }

    img {
        border-radius: 10px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 0;
    }
`

const meta = css`
    display: inline-block;
    margin-top: 10px;
    padding: 2px 15px;
    background-color: rgba(255, 255, 255, .32);
    border-radius: 10px;
    color: white;
    font-size: 1.3rem;

    span {
        margin-right: 6px;
    }
`

const whiteMode = css`
    h3, p {
        color: white;
    }
`