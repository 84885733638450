import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import ToolsList from "./toolsList"

import { css } from '@emotion/react'
import { colors } from "../styles/constants"

const ArticleCard = (props) => {
    const {
        slug,
        title,
        desc,
        image,
        tools,
    } = props

    // console.log("Tools", tools)

    return (
        <div>
            <div css={main}>
                {image && 
                    <div className="article-card__img" css={tools && makeSpace}>
                        <GatsbyImage image={image} alt={title} />
                        {tools && <div className="article-card__tools"><ToolsList tools={tools} hideTooltip /></div>}
                    </div>
                }
                <div className="article-card__main">
                    <Link to={`checklists/${slug}`}><h3>{title}</h3></Link>
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    )
}

export default ArticleCard

const makeSpace = css`
    margin-bottom: 40px;

    .article-card__tools {
        bottom: -60px;
    }
`

const main = css`
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    .article-card__img {
        position: relative;
        border-bottom: 1px solid ${colors.whitegrey};
    
        .article-card__tools {
            position: absolute;
            left: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .article-card__main {
        padding: 20px;

        h3 {
            margin: 0 0 10px;
            font-size: 1em;
        }

        a {
            color: ${colors.black};
            text-decoration: none;
        }

        p {
            margin: 0;
            color: ${colors.darkgrey};
            line-height: 1.3;
            font-size: .8em;
        }
    }
`

