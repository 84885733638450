import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { css } from '@emotion/react'
import { colors } from "../styles/constants"

const Testimonials = () => {
    const query = useStaticQuery(graphql`
        query TestimonialsQuery {
            testimonialImages: allFile(filter: {name: {regex: "/testimonial-[1-3]/"}}) {
                edges {
                    node {
                        id
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 60
                            )
                        }
                    }
                }
            }
        }
    `)
    
    return (
        <div>
            <div css={main}>
                {data.map( (testimonial, index) => (
                    <div css={item} key={testimonial.author}>
                        <div className="testimonial__meta">
                            <GatsbyImage image={query.testimonialImages.edges[index].node.childImageSharp.gatsbyImageData} alt="Témoignage"/>
                            <div>{testimonial.author} {}</div>
                        </div>
                        <div>{"⭐".repeat( Math.ceil( testimonial.rating ) )}</div>
                        <p>{testimonial.quote}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Testimonials

const main = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: start;
    margin: 66px 0 33px;

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`

const item = css`
    border: 1px solid ${colors.white};
    border-radius: 13px;
    padding: 20px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 4%) 0px 2px 2px;

    .gatsby-image-wrapper {
        border-radius: 100px;
        border: 2px solid ${colors.whitegrey};
        overflow: hidden;
    }

    p {
        margin: 0;
    }

    div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        > div:last-of-type {
            opacity: .5;
            margin-left: 15px;
        }
    }
`

const data = [
    {
        rating: 5,
        author: "Marie P.",
        quote: "J'ai pris quelques jours de vacances pour me former au tournage et montage, ce qui va maintenant bien me servir pour mon travail dans la communication et le community management. Leçons très claires et complètes !"
    },
    {
        rating: 5,
        author: "Etienne M.",
        quote: "J'avais besoin d'ajouter une compétence plus technique et recherché sur mon CV, et le tournage et montage vidéo m'ont permis de booster et renforcer mes compétences en réseaux sociaux."
    },
    {
        rating: 5,
        author: "Patrick S.",
        quote: "Adobe Rush est clairement un excellent logiciel. La formation Video Lab était rapide et m'a donné confiance en mes capacités à produire des dizaines de vidéos pour ma PME, sans dépenser pour une agence vidéo."
    },

]